// Create the class for managing the Google Maps autocomplete assignment

class Watcher {
    constructor(ref, path, fn, interval = 100) {
        this.ref = ref
        this.path = path
        this.interval = interval;
        this.fn = fn;
        this.prev = this.getValue()
    }

    getValue() {
        const keys = this.path.split(/\.|\[|\]/).filter(Boolean)
        return keys.reduce((c, key) => {
            return c[key]
        }, this.ref)
    }

    run() {
        let val = this.getValue()
        if (this.prev != val) {
            this.fn(val)
            this.prev = val
        }
    }
    
    stop() {
        if (!this.watchInterval) {
            return false
        }
        clearInterval(this.watchInterval)
        this.watchInterval = null
        this.run()
        return true
    }

    watch() {
        this.watchInterval = setInterval(() => {
            this.run()
        }, this.interval)
    }
}

class GoogleMapsAutoCompleteService {
    constructor({
        addressInput = '#address',
        validAddressInput = '#isValidAddress',
        zipInput = '#zip',
        validZipCodes = []
    }) {
        this.addressInput = addressInput;
        this.validAddressInput = validAddressInput;
        this.zipInput = zipInput;
        this.validZipCodes = validZipCodes;
        this.elements = [];
        this.autoCompletes = [];
        this.geocoder = null; // Will be initialized after Google Maps is loaded
    }

    /**
     * Init the GoogleMapsAutoCompleteService and get it ready to listen after Google Maps is loaded
     * @param {{addressInput: string, validAddressInput: string, zipInput: string, validZipCodes: array}} selectors
     */
    static setup(selectors = {
        addressInput: '#address',
        validAddressInput: '#isValidAddress',
        zipInput: '#zip',
        validZipCodes: []
    }) {
        const service = new GoogleMapsAutoCompleteService(selectors);

        // Define the initAutocomplete function if it's not already defined
        if (!window.initAutocomplete) {
            window.initAutocomplete = () => {
                service.listen();
            };
        }

        // If Google Maps scripts are already loaded, start listening immediately
        if (window.google?.maps?.places?.Autocomplete) {
            service.listen();
        }
    }

    listen() {
        // Initialize the geocoder now that Google Maps is loaded
        this.geocoder = new google.maps.Geocoder();

        window.addEventListener('heyflow-screen-view', (event) => {
            this.applyAutocomplete();
        });
        this.applyAutocomplete();
    }

    applyAutocomplete() {
        // Only apply to elements once
        const freshElements = [...document.querySelectorAll(this.addressInput)].filter(Boolean).filter(e => !this.elements.includes(e));
        freshElements.forEach(element => {
            let autocomplete = new window.google.maps.places.Autocomplete(
                element,
                {
                    types: ['address'],
                    componentRestrictions: { 'country': ['US'] },
                    fields: ['address_components', 'geometry']
                }
            );
            const watcher = new Watcher(element, 'value', () => this.completeAddressInput(element));
            autocomplete.addListener('place_changed', this.onPlaceChanged(element, autocomplete, watcher));
            element.addEventListener('blur', (e) => watcher.stop() && e.stopPropagation());
            element.addEventListener('focus', watcher.watch());
        });
        this.elements = this.elements.concat(freshElements);
    }

    onPlaceChanged(element, autocomplete, watcher) {
        return () => {
            const validAddressInput = this.getAddressValidInput();
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                console.log('No geometry found for place:', place); // Debugging log
                // User did not select a prediction; reset the input field
                element.placeholder = 'Enter a place';
                element.value = '';
                validAddressInput.value = "false";
            } else {
                const addressComponents = place.address_components;
                const address = this.formatAddress(addressComponents);
                const zip = this.getZipCode(addressComponents);
                this.getZipInput().value = zip;
                if (this.validZipCodes.includes(zip)) {
                    element.value = address;
                    watcher.prev = address
                    element.dispatchEvent(new Event('blur', {value: address}))
                    validAddressInput.value = "true";
                } else {
                    alert('Sorry, we do not service this area.');
                    element.value = '';
                    validAddressInput.value = "false";
                }
            }
        };
    }

    getZipCode(components) {
        for (let i = 0; i < components.length; i++) {
            const component = components[i];
            if (component.types.includes('postal_code')) {
                return component.long_name;
            }
        }
        return '';
    }

    formatAddress(components) {
        let street = '', city = '', state = '', zip = '';
        components.forEach(component => {
            const types = component.types;
            if (types.includes('street_number')) {
                street = component.long_name + ' ' + street;
            }
            if (types.includes('route')) {
                street += component.long_name;
            }
            if (types.includes('locality')) {
                city = component.long_name;
            }
            if (types.includes('administrative_area_level_1')) {
                state = component.long_name;  // Use long_name for the state full name
            }
            if (types.includes('postal_code')) {
                zip = component.long_name;
            }
        });
        return `${street}, ${city}, ${state}, ${zip}`;
    }

    hasValidAddress(components) {
        return !components.reduce((carry, component) => {
            component.types.forEach(type => {
                let idx = carry.indexOf(type)
                if (idx > -1) {
                    carry.splice(idx, 1)
                }
            })
            return carry
        }, ['route', 'locality', 'administrative_area_level_1', 'postal_code']).length
    }

    getAddressValidInput() {
        const el = [...document.querySelectorAll(this.validAddressInput)].filter(Boolean).find(() => true);
        if (!el) {
            console.error(`Address AutoComplete: No element found matching "${this.validAddressInput}"`);
            return {};
        }
        return el;
    }

    getZipInput() {
        const el = [...document.querySelectorAll(this.zipInput)].filter(Boolean).find(() => true);
        if (!el) {
            console.error(`Address AutoComplete: No element found matching "${this.zipInput}"`);
            return {};
        }
        return el;
    }

    completeAddressInput(element) {
        const address = element.value;
        if (address && this.geocoder) {
            this.geocoder.geocode({
                address: address,
                componentRestrictions: { country: 'US' }
            }, (results, status) => {
                const autoFillResults = results?.length === 1
                if (status === 'OK' && autoFillResults) {
                    const place = results[0];
                    const addressComponents = place.address_components;
                    const formattedAddress = this.formatAddress(addressComponents);
                    const zip = this.getZipCode(addressComponents);
                    this.getZipInput().value = zip;
                    const hasValidAddress = this.hasValidAddress(addressComponents)
                    if (hasValidAddress && this.validZipCodes.includes(zip)) {
                        element.value = formattedAddress;
                        element.blur()
                        this.getAddressValidInput().value = "true";
                    } else if (hasValidAddress) {
                        alert('Sorry, we do not service this area.');
                        this.getAddressValidInput().value = "false";
                    }
                } else {
                    this.getAddressValidInput().value = "false";
                }
            });
        } else {
            this.getAddressValidInput().value = "false";
        }
    }
}

const validZipCodes = [
    '80132', '80133', '80809', '80813', '80814', '80816', '80817', '80819', '80820', '80821', '80823', '80827', '80829', '80831', '80833', '80840', '80841', '80860', '80863', '80864',
    '80866', '80901', '80902', '80903', '80904', '80905', '80906', '80907', '80908', '80909', '80910', '80911', '80912', '80913', '80914', '80915', '80916', '80917', '80918', '80919',
    '80920', '80921', '80922', '80923', '80924', '80925', '80926', '80927', '80928', '80929', '80930', '80931', '80932', '80933', '80934', '80935', '80936', '80937', '80938', '80939',
    '80941', '80942', '80944', '80946', '80947', '80949', '80950', '80951', '80960', '80962', '80970', '80977', '80995', '80997', '81001', '81002', '81003', '81004', '81005', '81006',
    '81007', '81008', '81009', '81010', '81011', '81012', '81019', '81022', '81023', '81025', '81039', '81062', '81063', '81069', '81212', '81215', '81221', '81223', '81226', '81240',
    '81244', '81252', '81253', '81290', '80001', '80002', '80003', '80004', '80005', '80006', '80007', '80010', '80011', '80012', '80013', '80014', '80015', '80016', '80017', '80018',
    '80019', '80020', '80021', '80022', '80023', '80024', '80025', '80026', '80027', '80030', '80031', '80033', '80034', '80035', '80036', '80037', '80038', '80040', '80041', '80042',
    '80044', '80045', '80046', '80047', '80101', '80102', '80103', '80104', '80105', '80107', '80108', '80109', '80110', '80111', '80112', '80113', '80116', '80117', '80120', '80121',
    '80122', '80123', '80124', '80125', '80126', '80127', '80128', '80129', '80130', '80131', '80134', '80136', '80137', '80138', '80150', '80151', '80155', '80160', '80161', '80162',
    '80163', '80165', '80166', '80201', '80202', '80203', '80204', '80205', '80206', '80207', '80208', '80209', '80210', '80211', '80212', '80214', '80215', '80216', '80217', '80218',
    '80219', '80220', '80221', '80222', '80223', '80224', '80225', '80226', '80227', '80228', '80229', '80230', '80231', '80232', '80233', '80234', '80235', '80236', '80237', '80238',
    '80239', '80241', '80243', '80244', '80246', '80247', '80248', '80249', '80250', '80251', '80252', '80256', '80257', '80259', '80260', '80261', '80262', '80263', '80264', '80265',
    '80266', '80271', '80273', '80274', '80281', '80290', '80291', '80293', '80294', '80299', '80301', '80302', '80303', '80304', '80305', '80307', '80308', '80309', '80310', '80314',
    '80401', '80402', '80403', '80419', '80421', '80422', '80425', '80433', '80437', '80439', '80448', '80452', '80453', '80454', '80455', '80456', '80457', '80465', '80466', '80470',
    '80471', '80501', '80502', '80503', '80504', '80512', '80513', '80514', '80515', '80516', '80520', '80521', '80522', '80523', '80524', '80525', '80526', '80527', '80528', '80530',
    '80533', '80534', '80535', '80537', '80538', '80539', '80540', '80541', '80542', '80543', '80544', '80546', '80547', '80549', '80550', '80551', '80553', '80601', '80602', '80603',
    '80610', '80614', '80615', '80620', '80621', '80623', '80624', '80631', '80632', '80633', '80634', '80638', '80639', '80640', '80642', '80643', '80644', '80645', '80646', '80648',
    '80650', '80651', '80652', '80106', '80118', '80135', '80808', '80828', '80830', '80832', '80835', '88905', '89002', '89004', '89005', '89006', '89009', '89011', '89012', '89014',
    '89015', '89016', '89030', '89031', '89032', '89033', '89036', '89037', '89044', '89046', '89052', '89053', '89054', '89074', '89077', '89081', '89084', '89085', '89086', '89087',
    '89101', '89102', '89103', '89104', '89105', '89106', '89107', '89108', '89109', '89110', '89111', '89112', '89113', '89114', '89115', '89116', '89117', '89118', '89119', '89120',
    '89121', '89122', '89123', '89124', '89125', '89126', '89127', '89128', '89129', '89130', '89131', '89132', '89133', '89134', '89135', '89136', '89137', '89138', '89139', '89140',
    '89141', '89142', '89143', '89144', '89145', '89146', '89147', '89148', '89149', '89150', '89151', '89152', '89153', '89154', '89155', '89156', '89157', '89158', '89159', '89160',
    '89161', '89162', '89163', '89164', '89165', '89166', '89169', '89170', '89173', '89177', '89178', '89179', '89180', '89183', '89185', '89191', '89193', '89195', '89199', '87001',
    '87002', '87004', '87006', '87007', '87008', '87010', '87014', '87015', '87016', '87022', '87023', '87024', '87025', '87026', '87028', '87031', '87032', '87034', '87035', '87036',
    '87038', '87040', '87041', '87042', '87043', '87044', '87047', '87048', '87049', '87052', '87053', '87056', '87059', '87060', '87061', '87062', '87063', '87068', '87070', '87072',
    '87083', '87101', '87102', '87103', '87104', '87105', '87106', '87107', '87108', '87109', '87110', '87111', '87112', '87113', '87114', '87115', '87116', '87117', '87119', '87120',
    '87121', '87122', '87123', '87124', '87125', '87131', '87144', '87151', '87153', '87154', '87158', '87174', '87176', '87181', '87184', '87185', '87187', '87190', '87191', '87192',
    '87193', '87194', '87195', '87196', '87197', '87198', '87199', '87501', '87502', '87503', '87504', '87505', '87506', '87507', '87508', '87509', '87535', '87540', '87544', '87552',
    '87560', '87562', '87573', '87592', '87594', '87801', '87823', '87825', '87828', '87831', '87832', '88321', '87538', '87547', '87565', '87574', '87711', '87701', '27205', '27341',
    '27006', '27028', '27107', '27127', '27209', '27229', '27239', '27247', '27292', '27293', '27294', '27295', '27299', '27306', '27351', '27356', '27361', '27371', '27374', '28001',
    '28002', '28007', '28009', '28012', '28023', '28025', '28026', '28027', '28031', '28032', '28035', '28036', '28039', '28041', '28054', '28056', '28070', '28071', '28072', '28075',
    '28078', '28079', '28081', '28082', '28083', '28088', '28097', '28098', '28101', '28103', '28104', '28105', '28106', '28107', '28108', '28109', '28110', '28111', '28112', '28115',
    '28119', '28120', '28124', '28125', '28126', '28127', '28128', '28129', '28130', '28133', '28134', '28135', '28137', '28138', '28144', '28145', '28146', '28147', '28159', '28163',
    '28164', '28170', '28173', '28174', '28201', '28202', '28203', '28204', '28205', '28206', '28207', '28208', '28209', '28210', '28211', '28212', '28213', '28214', '28215', '28216',
    '28217', '28218', '28219', '28220', '28221', '28222', '28223', '28224', '28226', '28227', '28228', '28229', '28230', '28231', '28232', '28233', '28234', '28235', '28236', '28237',
    '28241', '28242', '28243', '28244', '28246', '28247', '28250', '28253', '28254', '28255', '28256', '28258', '28260', '28262', '28263', '28265', '28266', '28269', '28270', '28271',
    '28272', '28273', '28274', '28275', '28277', '28278', '28280', '28281', '28282', '28284', '28285', '28287', '28288', '28289', '28290', '28296', '28297', '28299', '29704', '29707',
    '29708', '29710', '29712', '29715', '29716', '29720', '29730', '29732', '29733', '29745', '27201', '27203', '27204', '27207', '27208', '27213', '27215', '27216', '27217', '27228',
    '27230', '27231', '27233', '27237', '27243', '27248', '27252', '27253', '27256', '27258', '27260', '27262', '27263', '27265', '27268', '27278', '27282', '27283', '27298', '27302',
    '27312', '27313', '27314', '27316', '27317', '27330', '27331', '27332', '27340', '27344', '27349', '27350', '27355', '27359', '27360', '27370', '27377', '27406', '27407', '27501',
    '27502', '27503', '27504', '27505', '27506', '27508', '27509', '27510', '27511', '27512', '27513', '27514', '27515', '27516', '27517', '27518', '27519', '27520', '27521', '27522',
    '27523', '27524', '27525', '27526', '27527', '27528', '27529', '27537', '27539', '27540', '27541', '27543', '27544', '27545', '27546', '27549', '27552', '27557', '27559', '27560',
    '27562', '27565', '27571', '27572', '27573', '27574', '27576', '27577', '27581', '27583', '27587', '27588', '27591', '27592', '27593', '27596', '27597', '27599', '27601', '27602',
    '27603', '27604', '27605', '27606', '27607', '27608', '27609', '27610', '27611', '27612', '27613', '27614', '27615', '27616', '27617', '27619', '27620', '27621', '27622', '27623',
    '27624', '27625', '27626', '27627', '27628', '27629', '27634', '27635', '27636', '27640', '27650', '27656', '27658', '27661', '27668', '27675', '27676', '27690', '27695', '27697',
    '27698', '27699', '27701', '27702', '27703', '27704', '27705', '27706', '27707', '27708', '27709', '27710', '27711', '27712', '27713', '27715', '27717', '27722', '28334', '28339',
    '27805', '27818', '27820', '27845', '27853', '27855', '27862', '27867', '27876', '27877', '27897', '27910', '27916', '27917', '27919', '27921', '27922', '27923', '27924', '27926',
    '27927', '27929', '27930', '27932', '27935', '27937', '27938', '27939', '27942', '27944', '27946', '27947', '27949', '27950', '27956', '27957', '27958', '27964', '27965', '27967',
    '27969', '27973', '27974', '27976', '27979', '27980', '27985', '27986', '43072', '43106', '43128', '43142', '43145', '43153', '43160', '43164', '45002', '45004', '45005', '45011',
    '45012', '45013', '45014', '45015', '45018', '45030', '45032', '45034', '45036', '45039', '45040', '45041', '45042', '45044', '45050', '45052', '45054', '45055', '45056', '45061',
    '45062', '45064', '45065', '45066', '45067', '45068', '45069', '45070', '45071', '45103', '45107', '45111', '45113', '45114', '45118', '45122', '45123', '45132', '45133', '45135',
    '45140', '45142', '45146', '45147', '45148', '45150', '45152', '45158', '45159', '45160', '45162', '45164', '45166', '45169', '45174', '45176', '45177', '45201', '45202', '45203',
    '45204', '45205', '45206', '45207', '45208', '45209', '45211', '45212', '45213', '45214', '45215', '45216', '45217', '45218', '45219', '45220', '45221', '45222', '45223', '45224',
    '45225', '45226', '45227', '45229', '45230', '45231', '45232', '45233', '45234', '45235', '45236', '45237', '45238', '45239', '45240', '45241', '45242', '45243', '45244', '45245',
    '45246', '45247', '45248', '45249', '45250', '45251', '45252', '45253', '45254', '45255', '45258', '45262', '45263', '45264', '45267', '45268', '45269', '45270', '45271', '45273',
    '45274', '45275', '45277', '45280', '45296', '45298', '45299', '45301', '45304', '45305', '45307', '45308', '45309', '45311', '45312', '45314', '45315', '45316', '45317', '45318',
    '45319', '45320', '45322', '45323', '45324', '45325', '45326', '45327', '45330', '45335', '45337', '45338', '45339', '45341', '45342', '45343', '45344', '45345', '45349', '45354',
    '45356', '45358', '45359', '45361', '45365', '45370', '45371', '45373', '45374', '45377', '45378', '45381', '45383', '45384', '45385', '45387', '45389', '45401', '45402', '45403',
    '45404', '45405', '45406', '45409', '45410', '45412', '45413', '45414', '45415', '45416', '45417', '45419', '45420', '45422', '45423', '45424', '45426', '45428', '45429', '45430',
    '45431', '45432', '45433', '45434', '45435', '45437', '45439', '45440', '45441', '45448', '45449', '45458', '45459', '45469', '45470', '45475', '45479', '45481', '45482', '45490',
    '45504', '45506', '45999', '43001', '43002', '43004', '43009', '43010', '43011', '43013', '43015', '43016', '43017', '43018', '43021', '43023', '43026', '43029', '43031', '43032',
    '43033', '43035', '43040', '43041', '43044', '43045', '43046', '43047', '43054', '43060', '43061', '43062', '43064', '43065', '43068', '43069', '43073', '43074', '43077', '43078',
    '43081', '43082', '43084', '43085', '43086', '43103', '43105', '43109', '43110', '43112', '43113', '43116', '43117', '43119', '43123', '43125', '43126', '43136', '43137', '43140',
    '43146', '43147', '43162', '43194', '43195', '43199', '43201', '43202', '43203', '43204', '43205', '43206', '43207', '43209', '43210', '43211', '43212', '43213', '43214', '43215',
    '43216', '43217', '43218', '43219', '43220', '43221', '43222', '43223', '43224', '43226', '43227', '43228', '43229', '43230', '43231', '43232', '43234', '43235', '43236', '43240',
    '43251', '43260', '43266', '43268', '43270', '43271', '43272', '43279', '43287', '43291', '43319', '43336', '43357', '43360', '45369', '43143', '45503', '45368', '45502', '45505',
    '73003', '73007', '73008', '73010', '73012', '73013', '73014', '73016', '73019', '73020', '73025', '73026', '73027', '73028', '73034', '73036', '73044', '73045', '73049', '73050',
    '73051', '73054', '73058', '73059', '73064', '73065', '73066', '73068', '73069', '73070', '73071', '73072', '73078', '73080', '73083', '73084', '73085', '73089', '73090', '73093',
    '73097', '73099', '73101', '73102', '73103', '73104', '73105', '73106', '73107', '73108', '73109', '73110', '73111', '73112', '73113', '73114', '73115', '73116', '73117', '73118',
    '73119', '73120', '73121', '73122', '73123', '73124', '73125', '73126', '73127', '73128', '73129', '73130', '73131', '73132', '73134', '73135', '73136', '73137', '73139', '73140',
    '73141', '73142', '73143', '73144', '73145', '73146', '73147', '73148', '73149', '73150', '73151', '73152', '73153', '73154', '73155', '73156', '73157', '73159', '73160', '73162',
    '73163', '73164', '73165', '73167', '73169', '73170', '73172', '73173', '73178', '73179', '73184', '73185', '73189', '73190', '73194', '73195', '73196', '73750', '73762', '74023',
    '74026', '74028', '74046', '74059', '74079', '74801', '74802', '74804', '74824', '74832', '74834', '74840', '74851', '74852', '74855', '74857', '74860', '74864', '74868', '74869',
    '74873', '74875', '74878', '74881', '73022', '74008', '74010', '74011', '74012', '74013', '74014', '74015', '74019', '74020', '74021', '74033', '74035', '74036', '74037', '74039',
    '74041', '74043', '74044', '74047', '74050', '74055', '74060', '74063', '74066', '74067', '74070', '74073', '74101', '74102', '74103', '74104', '74105', '74106', '74107', '74108',
    '74110', '74112', '74114', '74115', '74116', '74117', '74119', '74120', '74121', '74126', '74127', '74128', '74129', '74130', '74131', '74132', '74133', '74134', '74135', '74136',
    '74137', '74141', '74145', '74146', '74147', '74148', '74149', '74150', '74152', '74153', '74155', '74156', '74157', '74158', '74159', '74169', '74170', '74171', '74172', '74182',
    '74186', '74187', '74192', '74193', '74429', '74436', '97002', '97003', '97004', '97005', '97006', '97007', '97008', '97009', '97013', '97014', '97015', '97016', '97017', '97019',
    '97020', '97022', '97023', '97024', '97026', '97027', '97030', '97031', '97032', '97034', '97035', '97036', '97038', '97040', '97041', '97042', '97044', '97045', '97048', '97051',
    '97053', '97054', '97055', '97056', '97060', '97062', '97064', '97068', '97070', '97071', '97075', '97076', '97077', '97078', '97079', '97080', '97086', '97089', '97101', '97106',
    '97109', '97111', '97113', '97114', '97115', '97116', '97117', '97119', '97123', '97124', '97125', '97127', '97128', '97129', '97132', '97133', '97137', '97140', '97144', '97148',
    '97201', '97202', '97203', '97204', '97205', '97206', '97207', '97208', '97209', '97210', '97211', '97212', '97213', '97214', '97215', '97216', '97217', '97218', '97219', '97220',
    '97221', '97222', '97223', '97224', '97225', '97227', '97228', '97229', '97230', '97231', '97232', '97233', '97236', '97238', '97239', '97240', '97242', '97250', '97251', '97252',
    '97253', '97256', '97258', '97266', '97267', '97268', '97269', '97280', '97281', '97282', '97283', '97286', '97290', '97291', '97292', '97293', '97294', '97296', '97298', '97301',
    '97302', '97303', '97304', '97305', '97306', '97307', '97308', '97309', '97310', '97311', '97312', '97313', '97314', '97317', '97321', '97322', '97325', '97330', '97333', '97335',
    '97338', '97351', '97352', '97355', '97361', '97362', '97371', '97373', '97374', '97375', '97378', '97381', '97383', '97385', '97389', '97392', '97396', '73301', '73344', '76501',
    '76502', '76503', '76504', '76508', '76511', '76513', '76522', '76527', '76528', '76530', '76533', '76534', '76537', '76539', '76540', '76541', '76542', '76543', '76544', '76547',
    '76548', '76549', '76554', '76557', '76559', '76569', '76571', '76573', '76574', '78602', '78605', '78608', '78610', '78612', '78613', '78615', '78617', '78619', '78620', '78621',
    '78626', '78627', '78628', '78630', '78633', '78634', '78641', '78642', '78645', '78646', '78650', '78651', '78652', '78653', '78654', '78659', '78660', '78663', '78664', '78665',
    '78669', '78673', '78674', '78680', '78681', '78682', '78683', '78691', '78701', '78702', '78703', '78704', '78705', '78708', '78709', '78710', '78711', '78712', '78713', '78714',
    '78715', '78716', '78717', '78718', '78719', '78720', '78721', '78722', '78723', '78724', '78725', '78726', '78727', '78728', '78729', '78730', '78731', '78732', '78733', '78734',
    '78735', '78736', '78737', '78738', '78739', '78741', '78742', '78744', '78745', '78746', '78747', '78748', '78749', '78750', '78751', '78752', '78753', '78754', '78755', '78756',
    '78757', '78758', '78759', '78760', '78761', '78762', '78763', '78764', '78765', '78766', '78767', '78768', '78769', '78772', '78773', '78774', '78778', '78779', '78783', '78799',
    '78957', '75001', '75002', '75006', '75007', '75009', '75010', '75011', '75013', '75014', '75015', '75016', '75017', '75019', '75022', '75023', '75024', '75025', '75026', '75027',
    '75028', '75029', '75030', '75032', '75033', '75034', '75035', '75036', '75038', '75039', '75040', '75041', '75042', '75043', '75044', '75045', '75046', '75047', '75048', '75049',
    '75050', '75051', '75052', '75053', '75054', '75056', '75057', '75059', '75060', '75061', '75062', '75063', '75064', '75065', '75067', '75068', '75069', '75070', '75071', '75072',
    '75074', '75075', '75077', '75078', '75080', '75081', '75082', '75083', '75085', '75086', '75087', '75088', '75089', '75093', '75094', '75098', '75099', '75104', '75106', '75114',
    '75115', '75116', '75119', '75123', '75125', '75126', '75132', '75134', '75137', '75138', '75141', '75142', '75146', '75149', '75150', '75152', '75154', '75157', '75158', '75159',
    '75160', '75165', '75166', '75167', '75172', '75173', '75180', '75181', '75182', '75185', '75187', '75189', '75201', '75202', '75203', '75204', '75205', '75206', '75207', '75208',
    '75209', '75210', '75211', '75212', '75214', '75215', '75216', '75217', '75218', '75219', '75220', '75221', '75222', '75223', '75224', '75225', '75226', '75227', '75228', '75229',
    '75230', '75231', '75232', '75233', '75234', '75235', '75236', '75237', '75238', '75240', '75241', '75242', '75243', '75244', '75246', '75247', '75248', '75249', '75250', '75251',
    '75252', '75253', '75254', '75260', '75261', '75262', '75263', '75264', '75265', '75266', '75267', '75270', '75275', '75277', '75283', '75284', '75285', '75287', '75301', '75303',
    '75312', '75313', '75315', '75320', '75326', '75336', '75339', '75342', '75354', '75355', '75356', '75357', '75358', '75359', '75360', '75367', '75368', '75370', '75371', '75372',
    '75373', '75374', '75376', '75378', '75379', '75380', '75381', '75382', '75389', '75390', '75391', '75392', '75393', '75394', '75395', '75397', '75398', '75407', '75409', '75442',
    '75454', '76001', '76002', '76003', '76004', '76005', '76006', '76007', '76008', '76009', '76010', '76011', '76012', '76013', '76014', '76015', '76016', '76017', '76018', '76019',
    '76020', '76021', '76022', '76023', '76028', '76031', '76034', '76035', '76036', '76039', '76040', '76044', '76051', '76052', '76053', '76054', '76058', '76060', '76061', '76063',
    '76065', '76071', '76078', '76082', '76084', '76085', '76086', '76087', '76092', '76094', '76095', '76096', '76097', '76098', '76099', '76101', '76102', '76103', '76104', '76105',
    '76106', '76107', '76108', '76109', '76110', '76111', '76112', '76113', '76114', '76115', '76116', '76117', '76118', '76119', '76120', '76121', '76122', '76123', '76124', '76126',
    '76127', '76129', '76130', '76131', '76132', '76133', '76134', '76135', '76136', '76137', '76140', '76147', '76148', '76150', '76155', '76161', '76162', '76163', '76164', '76166',
    '76177', '76179', '76180', '76181', '76182', '76185', '76190', '76191', '76192', '76193', '76195', '76196', '76197', '76198', '76199', '76201', '76202', '76203', '76204', '76205',
    '76206', '76207', '76208', '76209', '76210', '76226', '76227', '76244', '76247', '76248', '76258', '76259', '76262', '75097', '75121', '75424', '76249', '76266', '77001', '77002',
    '77003', '77004', '77005', '77006', '77007', '77008', '77009', '77010', '77011', '77012', '77013', '77014', '77015', '77016', '77017', '77018', '77019', '77020', '77021', '77022',
    '77023', '77024', '77025', '77026', '77027', '77028', '77029', '77030', '77031', '77032', '77033', '77034', '77035', '77036', '77037', '77038', '77039', '77040', '77041', '77042',
    '77043', '77044', '77045', '77046', '77047', '77048', '77049', '77050', '77051', '77052', '77053', '77054', '77055', '77056', '77057', '77058', '77059', '77060', '77061', '77062',
    '77063', '77064', '77065', '77066', '77067', '77068', '77069', '77070', '77071', '77072', '77073', '77074', '77075', '77076', '77077', '77078', '77079', '77080', '77081', '77082',
    '77083', '77084', '77085', '77086', '77087', '77088', '77089', '77090', '77091', '77092', '77093', '77094', '77095', '77096', '77098', '77099', '77201', '77202', '77203', '77204',
    '77205', '77206', '77207', '77208', '77209', '77210', '77212', '77213', '77215', '77216', '77217', '77218', '77219', '77220', '77221', '77222', '77223', '77224', '77225', '77226',
    '77227', '77228', '77229', '77230', '77231', '77233', '77234', '77235', '77236', '77237', '77238', '77240', '77241', '77242', '77243', '77244', '77245', '77248', '77249', '77251',
    '77252', '77253', '77254', '77255', '77256', '77257', '77258', '77259', '77261', '77262', '77263', '77265', '77266', '77267', '77268', '77269', '77270', '77271', '77272', '77273',
    '77274', '77275', '77277', '77279', '77280', '77282', '77284', '77287', '77288', '77289', '77290', '77291', '77292', '77293', '77297', '77299', '77301', '77302', '77304', '77305',
    '77306', '77315', '77316', '77325', '77327', '77328', '77336', '77337', '77338', '77339', '77345', '77346', '77347', '77354', '77355', '77357', '77362', '77365', '77372', '77373',
    '77375', '77377', '77379', '77380', '77381', '77382', '77383', '77384', '77385', '77386', '77387', '77388', '77389', '77391', '77396', '77401', '77402', '77406', '77407', '77410',
    '77411', '77413', '77429', '77433', '77447', '77449', '77450', '77459', '77461', '77469', '77471', '77477', '77478', '77479', '77481', '77487', '77489', '77491', '77492', '77493',
    '77494', '77496', '77497', '77498', '77501', '77502', '77503', '77504', '77505', '77506', '77507', '77508', '77510', '77511', '77512', '77517', '77518', '77520', '77521', '77522',
    '77523', '77530', '77532', '77535', '77536', '77539', '77545', '77546', '77547', '77549', '77554', '77562', '77563', '77565', '77568', '77571', '77572', '77573', '77574', '77575',
    '77578', '77580', '77581', '77583', '77584', '77586', '77587', '77588', '77590', '77591', '77597', '77598', '78002', '78004', '78005', '78006', '78009', '78011', '78015', '78016',
    '78023', '78026', '78027', '78039', '78050', '78052', '78054', '78056', '78059', '78063', '78064', '78065', '78066', '78069', '78070', '78073', '78101', '78108', '78109', '78112',
    '78114', '78115', '78121', '78122', '78123', '78124', '78130', '78131', '78132', '78133', '78135', '78140', '78148', '78150', '78152', '78154', '78155', '78156', '78160', '78161',
    '78163', '78201', '78202', '78203', '78204', '78205', '78206', '78207', '78208', '78209', '78210', '78211', '78212', '78213', '78214', '78215', '78216', '78217', '78218', '78219',
    '78220', '78221', '78222', '78223', '78224', '78225', '78226', '78227', '78228', '78229', '78230', '78231', '78232', '78233', '78234', '78235', '78236', '78237', '78238', '78239',
    '78240', '78241', '78242', '78243', '78244', '78245', '78246', '78247', '78248', '78249', '78250', '78251', '78252', '78253', '78254', '78255', '78256', '78257', '78258', '78259',
    '78260', '78261', '78263', '78264', '78265', '78266', '78268', '78269', '78270', '78278', '78279', '78280', '78283', '78284', '78285', '78288', '78289', '78291', '78292', '78293',
    '78294', '78295', '78296', '78297', '78298', '78299', '78606', '78622', '78623', '78629', '78632', '78638', '78640', '78644', '78648', '78655', '78656', '78658', '78661', '78662',
    '78666', '78670', '78676', '78861', '78953', '76578', '78667', '78959', '78616', '84003', '84004', '84005', '84006', '84009', '84010', '84011', '84013', '84014', '84015', '84016',
    '84020', '84025', '84029', '84032', '84036', '84037', '84040', '84041', '84042', '84043', '84044', '84045', '84047', '84049', '84050', '84054', '84056', '84057', '84058', '84059',
    '84060', '84061', '84062', '84065', '84067', '84068', '84070', '84071', '84074', '84075', '84081', '84082', '84084', '84087', '84088', '84090', '84091', '84092', '84093', '84094',
    '84095', '84096', '84097', '84098', '84101', '84102', '84103', '84104', '84105', '84106', '84107', '84108', '84109', '84110', '84111', '84112', '84113', '84114', '84115', '84116',
    '84117', '84118', '84119', '84120', '84121', '84122', '84123', '84124', '84125', '84126', '84127', '84128', '84129', '84130', '84131', '84132', '84133', '84134', '84136', '84138',
    '84139', '84141', '84143', '84145', '84147', '84148', '84150', '84151', '84152', '84157', '84158', '84165', '84170', '84171', '84180', '84184', '84189', '84190', '84199', '84201',
    '84244', '84310', '84315', '84317', '84401', '84402', '84403', '84404', '84405', '84407', '84408', '84409', '84412', '84415', '84601', '84602', '84603', '84604', '84605', '84606',
    '84626', '84628', '84633', '84645', '84648', '84651', '84653', '84655', '84660', '84663', '84664', '84340', '84414', '20101', '20102', '20103', '20104', '20105', '20106', '20108',
    '20109', '20110', '20111', '20112', '20113', '20115', '20116', '20117', '20118', '20119', '20120', '20121', '20122', '20124', '20128', '20129', '20130', '20131', '20132', '20134',
    '20135', '20136', '20137', '20138', '20139', '20140', '20141', '20142', '20143', '20144', '20146', '20147', '20148', '20149', '20151', '20152', '20153', '20155', '20156', '20158',
    '20159', '20160', '20163', '20164', '20165', '20166', '20167', '20168', '20169', '20170', '20171', '20172', '20175', '20176', '20177', '20178', '20180', '20181', '20182', '20184',
    '20185', '20186', '20187', '20188', '20189', '20190', '20191', '20192', '20194', '20195', '20196', '20197', '20198', '20598', '22003', '22009', '22015', '22025', '22026', '22027',
    '22030', '22031', '22032', '22033', '22034', '22035', '22036', '22037', '22038', '22039', '22040', '22041', '22042', '22043', '22044', '22046', '22060', '22066', '22079', '22081',
    '22082', '22095', '22096', '22101', '22102', '22103', '22106', '22107', '22108', '22109', '22116', '22118', '22119', '22121', '22122', '22124', '22125', '22134', '22150', '22151',
    '22152', '22153', '22156', '22158', '22159', '22160', '22161', '22172', '22180', '22181', '22182', '22183', '22185', '22191', '22192', '22193', '22194', '22195', '22199', '22201',
    '22202', '22203', '22204', '22205', '22206', '22207', '22210', '22211', '22212', '22213', '22214', '22215', '22216', '22217', '22219', '22225', '22226', '22227', '22230', '22241',
    '22242', '22245', '22246', '22301', '22302', '22303', '22304', '22305', '22306', '22307', '22308', '22309', '22310', '22311', '22312', '22313', '22314', '22315', '22331', '22332',
    '22333', '22334', '22403', '22406', '22463', '22471', '22545', '22554', '22555', '22556', '22601', '22602', '22603', '22604', '22610', '22611', '22620', '22622', '22623', '22624',
    '22625', '22626', '22627', '22630', '22637', '22639', '22640', '22641', '22642', '22643', '22644', '22645', '22646', '22649', '22650', '22652', '22654', '22655', '22656', '22657',
    '22660', '22663', '22664', '22711', '22712', '22713', '22714', '22715', '22716', '22718', '22719', '22720', '22722', '22724', '22725', '22726', '22728', '22734', '22735', '22736',
    '22737', '22739', '22740', '22741', '22742', '22743', '22746', '22747', '22749', '22835', '22067', '22430', '22405', '22508', '22701', '22727', '22733', '23168', '22448', '23147',
    '23127', '23011', '23064', '23031', '22433', '22401', '22407', '22408', '22412', '22427', '22428', '22432', '22435', '22436', '22437', '22438', '22443', '22446', '22451', '22454',
    '22456', '22460', '22469', '22472', '22473', '22476', '22480', '22481', '22482', '22485', '22488', '22501', '22503', '22504', '22507', '22509', '22511', '22513', '22514', '22517',
    '22520', '22523', '22524', '22526', '22528', '22529', '22534', '22535', '22538', '22539', '22542', '22544', '22546', '22547', '22548', '22551', '22552', '22553', '22560', '22565',
    '22567', '22570', '22572', '22576', '22578', '22579', '22580', '22581', '22709', '22723', '22729', '22730', '22731', '22732', '22738', '22748', '22901', '22902', '22903', '22904',
    '22905', '22906', '22907', '22908', '22909', '22910', '22911', '22923', '22932', '22935', '22936', '22937', '22940', '22942', '22945', '22947', '22948', '22957', '22959', '22960',
    '22963', '22965', '22968', '22972', '22973', '22974', '22987', '22989', '23002', '23004', '23005', '23009', '23014', '23015', '23022', '23023', '23024', '23027', '23030', '23032',
    '23038', '23039', '23040', '23047', '23055', '23058', '23059', '23060', '23063', '23065', '23067', '23069', '23075', '23079', '23083', '23084', '23085', '23086', '23089', '23091',
    '23093', '23102', '23103', '23105', '23106', '23108', '23110', '23111', '23112', '23113', '23114', '23115', '23116', '23117', '23120', '23123', '23124', '23126', '23129', '23139',
    '23140', '23141', '23146', '23148', '23150', '23153', '23160', '23161', '23162', '23170', '23173', '23175', '23177', '23180', '23181', '23192', '23218', '23219', '23220', '23221',
    '23222', '23223', '23224', '23225', '23226', '23227', '23228', '23229', '23230', '23231', '23232', '23233', '23234', '23235', '23236', '23237', '23238', '23241', '23242', '23249',
    '23250', '23255', '23260', '23261', '23269', '23273', '23274', '23276', '23278', '23279', '23282', '23284', '23285', '23286', '23288', '23289', '23290', '23291', '23292', '23293',
    '23294', '23295', '23297', '23298', '23801', '23803', '23804', '23805', '23806', '23821', '23822', '23824', '23830', '23831', '23832', '23833', '23834', '23836', '23838', '23840',
    '23841', '23842', '23843', '23850', '23856', '23860', '23868', '23872', '23873', '23875', '23876', '23882', '23885', '23889', '23893', '23894', '23901', '23909', '23915', '23917',
    '23919', '23920', '23922', '23924', '23930', '23936', '23937', '23938', '23942', '23943', '23944', '23947', '23950', '23952', '23954', '23955', '23958', '23960', '23966', '23970',
    '23974', '24590', '23149', '23847', '23867', '23001', '23003', '23018', '23021', '23025', '23035', '23043', '23045', '23050', '23056', '23061', '23062', '23066', '23068', '23070',
    '23071', '23072', '23076', '23081', '23090', '23092', '23107', '23109', '23119', '23125', '23128', '23130', '23131', '23138', '23154', '23155', '23156', '23163', '23169', '23176',
    '23178', '23183', '23184', '23185', '23186', '23187', '23188', '23190', '23304', '23314', '23315', '23320', '23321', '23322', '23323', '23324', '23325', '23326', '23327', '23328',
    '23397', '23424', '23430', '23431', '23432', '23433', '23434', '23435', '23436', '23437', '23438', '23439', '23450', '23451', '23452', '23453', '23454', '23455', '23456', '23457',
    '23458', '23459', '23460', '23461', '23462', '23463', '23464', '23465', '23466', '23467', '23471', '23479', '23487', '23501', '23502', '23503', '23504', '23505', '23506', '23507',
    '23508', '23509', '23510', '23511', '23513', '23514', '23515', '23517', '23518', '23519', '23523', '23529', '23541', '23551', '23601', '23602', '23603', '23604', '23605', '23606',
    '23607', '23608', '23609', '23612', '23628', '23630', '23651', '23661', '23662', '23663', '23664', '23665', '23666', '23667', '23668', '23669', '23670', '23681', '23690', '23691',
    '23692', '23693', '23694', '23696', '23701', '23702', '23703', '23704', '23705', '23707', '23708', '23709', '23827', '23828', '23829', '23837', '23839', '23844', '23846', '23851',
    '23866', '23874', '23878', '23881', '23883', '23888', '23890', '23897', '23898', '23884', '23891', '23899', '22558', '20682', '22404', '22402'
];

GoogleMapsAutoCompleteService.setup({
    addressInput: '*[data-variable=address]',
    validAddressInput: '*[data-variable=isValidAddress]',
    zipInput: '*[data-variable=zip]',
    validZipCodes
})
